import React from 'react';
import { CompactPicker } from 'react-color';
import reactCSS from 'reactcss';

type Props = {
  field: {
    name: string;
    value: string;
  };
  form: {
    setFieldValue: (name: string, color: string) => void;
  };
};
type State = { color?: string; displayColorPicker?: boolean };
export default class ColorPicker extends React.Component<Props, State> {
  state: State = {
    displayColorPicker: false
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleChange = color => {
    this.setState({ color: color.rgb });
    const {
      field: { name },
      form: { setFieldValue }
    } = this.props;
    setFieldValue(name, color.hex);
    this.handleClose();
  };

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  render() {
    const styles = reactCSS({
      default: {
        color: {
          background: `${this.props.field.value}`,
          borderRadius: '2px',
          height: '14px',
          width: '36px'
        },
        cover: {
          bottom: '0px',
          left: '0px',
          position: 'fixed',
          right: '0px',
          top: '0px'
        },
        popover: {
          position: 'absolute',
          zIndex: '2'
        },
        swatch: {
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          cursor: 'pointer',
          display: 'inline-block',
          padding: '5px'
        }
      }
    });

    const { field, ...custom } = this.props;

    return (
      <div>
        <button onClick={this.handleClick} style={styles.swatch} type="button">
          <div data-testid="colorSwatch" style={styles.color} />
        </button>
        {this.state.displayColorPicker ? (
          <div style={styles.popover}>
            <button
              onClick={this.handleClose}
              style={styles.cover}
              type="button"
            />
            <CompactPicker
              {...field}
              {...custom}
              color={this.state.color}
              onChange={this.handleChange}
              onClick={this.handleClose}
            />
          </div>
        ) : null}
      </div>
    );
  }
}
