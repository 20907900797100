import React, { PropsWithChildren, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { HistoryRouter as Router } from 'redux-first-history/rr6';

import { history } from './configureStore';
import { getUserToken } from './helpers';
import Connectors from './scenes/Connectors/Connectors';
import Login from './scenes/Login/Login';
import NothingFound from './scenes/NothingFound/NothingFound';
import Offering from './scenes/Offering/Offering';
import Offerings from './scenes/Offerings/Offerings';
import OfferingsArchived from './scenes/OfferingsArchived/OfferingsArchived';
import SignUp from './scenes/SignUp/SignUp';
import UserControlPanel from './scenes/UserControlPanel/UserControlPanel';

const PrivateRoute: React.FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (!getUserToken()) navigate('/login', { replace: true });
  }, [navigate]);

  return <>{children}</>;
};

const PublicRoute: React.FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (getUserToken()) navigate('/', { replace: true });
  }, [navigate]);

  return <>{children}</>;
};

export default class AppRoutes extends React.Component {
  render() {
    return (
      <Router history={history}>
        <Routes>
          <Route
            element={
              <PublicRoute>
                <Login />
              </PublicRoute>
            }
            path="/login"
          />
          <Route
            element={
              <PublicRoute>
                <SignUp />
              </PublicRoute>
            }
            path="/signup"
          />
          <Route
            element={
              <PrivateRoute>
                <Offerings />
              </PrivateRoute>
            }
            path="/"
          />
          <Route
            element={
              <PrivateRoute>
                <UserControlPanel />
              </PrivateRoute>
            }
            path="/settings"
          />
          <Route
            element={
              <PrivateRoute>
                <Connectors />
              </PrivateRoute>
            }
            path="/connectors"
          />
          <Route
            element={
              <PrivateRoute>
                <Offerings />
              </PrivateRoute>
            }
            path="/offerings"
          />
          <Route
            element={
              <PrivateRoute>
                <OfferingsArchived />
              </PrivateRoute>
            }
            path="/archived-offerings"
          />
          <Route
            element={
              <PrivateRoute>
                <Offering />
              </PrivateRoute>
            }
            path="/offering/:id"
          />
          <Route element={<NothingFound />} path="*" />
        </Routes>
      </Router>
    );
  }
}
